import React, { FC } from "react";
import { graphql, Link, PageProps } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import {
  ContentfulPuffEdge,
  ContentfulPuffConnection,
  ContentfulHem,
} from "../../types/graphql-types";

const Puff = ({ node: { lnk, image, header, body } }: ContentfulPuffEdge) => {
  return (
    <div className="col-md-4">
      <Link className="card" to={`${lnk?.toLowerCase()}/`}>
        {image?.description ? (
          <Img
            fluid={image?.fluid as any}
            className="card-image"
            alt={image?.description}
            loading="eager"
          />
        ) : (
          <Img
            fluid={image?.fluid as any}
            className="card-image"
            loading="eager"
          />
        )}
        <div className="card-content">
          <h3 className="card-content__header">{header}</h3>
          <p className="card-content__paragraph">{body}</p>
        </div>
      </Link>
    </div>
  );
};

type IndexProps = {
  contentfulHem: ContentfulHem & any;
  allContentfulPuff: ContentfulPuffConnection;
};

const IndexPage: FC<PageProps<IndexProps>> = ({
  data: {
    contentfulHem: {
      hero: { description: heroDescription, fluid: heroFluid },
      header,
      body,
      buttonText,
      buttonLink,
    },
    allContentfulPuff: { edges: puffar },
  },
}) => {
  return (
    <Layout>
      <SEO title="Barnstorlekar.se" description="Barnstorlekar.se" />
      <main className="main home">
        <div className="container">
          <div className="hero">
            <div className="hero-textblock">
              <h1 className="hero-textblock__h">{header}</h1>
              <p className="hero-textblock__p">{body}</p>
              <a className="hero-textblock__button" href={buttonLink}>
                {buttonText}
              </a>
            </div>
            {heroDescription ? (
              <Img
                fluid={heroFluid}
                alt={heroDescription}
                className="hero-image"
                loading="eager"
              />
            ) : (
              <Img fluid={heroFluid} className="hero-image" loading="eager" />
            )}
          </div>
          <div className="row card-wrapper">
            {puffar.map(edge => (
              <Puff {...edge} key={edge.node.id} />
            ))}
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  {
    contentfulHem {
      hero {
        fluid(maxWidth: 780, quality: 100) {
          ...GatsbyContentfulFluid
        }
        description
      }
      header
      body
      buttonText
      buttonLink
    }
    allContentfulPuff {
      edges {
        node {
          image {
            fluid(maxWidth: 552, quality: 100) {
              ...GatsbyContentfulFluid
            }
            description
          }
          id
          header
          body
          lnk
        }
      }
    }
  }
`;
